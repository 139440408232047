import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Contact() {
  return  (
      <Layout>
      <SEO 
        pagetitle="お問い合わせ"
      />
      <div className="keni-main_wrap">
        <div className="keni-main_outer">
          {/*▼▼ メインコンテンツ ▼▼*/}
          <main id="main" className="keni-main">
            <div className="keni-main_inner">
              <div className="keni-section_wrap">
                <article className="keni-section"><h1>お問い合わせ</h1>
                  <div className="article-body">
                    <p>株式会社わくわくスタディワールドへのお問い合わせは下記のフォームをご利用ください。</p>
                    <p>出版書籍に関する正誤表などのお問い合わせは，各販売元にお願いいたします。<br />
                      勉強法やIT，AIなどの技術，情報処理技術者試験などの個別のご質問には対応しておりません。</p>
                    <p>有料広告，人材派遣などあらゆる営業は固くお断りいたします。</p>
                    <p><iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdockJBUtqGTuoLydUqBm9nam2_tTQ_RTEBJ6_nmyO7JMr3Ag/viewform?embedded=true" width={760} height={1200} frameBorder={0} marginHeight={0} marginWidth={0}>読み込んでいます&amp;#8230;</iframe></p>
                  </div>{/*article-body*/}
                </article>{/*keni-section*/}
              </div>
            </div>{/*keni-main_inner*/}
          </main>{/*keni-main*/}
          {/*▲▲ メインコンテンツ ▲▲*/}
        </div>{/*keni-main_outer*/}
      </div>{/*keni-main_wrap*/}
      </Layout>
  )
}